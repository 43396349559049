import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Skill Practice: Pistols & Parallette L Holds`}</p>
    <p>{`then,`}</p>
    <p>{`1/2 Mile Assault Bike Buy In`}</p>
    <p>{`3 Rounds of:`}</p>
    <p>{`20 Pistols (total)`}</p>
    <p>{`20 KBS’s (53/35)`}</p>
    <p>{`10 HSPU’s`}</p>
    <p>{`1/2 Mile Assault Bike Cash Out`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We will be adding a 9:00am class on Tuesdays and Thursdays starting
Thursday, August 2nd!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Sunday, August 5th we are having our Ville get together at the
Barnetts house, everyone is invited!  We’ll watch the final day of the
CrossFit Games, eat, swim, and we’ll even have a few chances to workout.
 Call, text or email Daniel for the address.  Family friendly so bring
the kids!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      